html, body {
  min-height: 100;
  background-color: black;
  font-size: 20px;
}

* {
  color: white;
  font-family: 'Inconsolata';
}
